import { render, staticRenderFns } from "./BannersSlider.vue?vue&type=template&id=552de2d3&scoped=true"
import script from "./BannersSlider.vue?vue&type=script&setup=true&lang=ts"
export * from "./BannersSlider.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BannersSlider.vue?vue&type=style&index=0&id=552de2d3&prod&lang=sass"
import style1 from "./BannersSlider.vue?vue&type=style&index=1&id=552de2d3&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552de2d3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiIcon.vue').default,UiMarkerPoint: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiMarkerPoint.vue').default,UiSectionTitle: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiSectionTitle.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiButton.vue').default})
