import { render, staticRenderFns } from "./ServiceForm.vue?vue&type=template&id=ed0e978e"
import script from "./ServiceForm.vue?vue&type=script&lang=ts"
export * from "./ServiceForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInput: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiInput.vue').default,UiSelect: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiSelect.vue').default,UiAutocomplete: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiAutocomplete.vue').default,UiTextarea: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiTextarea.vue').default,UiCheckbox: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiCheckbox.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/ui/UiButton.vue').default,InfoModal: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@3/components/InfoModal.vue').default})
